<template>
  <v-container>
    <div class="mx-auto pt-4 my-survey-wrapper">
      <v-card flat>
        <v-card-text class="pa-0">
          <v-form ref="form" v-model="valid" lazy-validation>
            <div v-for="(q, idx) in questions" class="survey-question" :key="`q-${idx}`">
              <div class="question">Q{{1 + idx}}. {{q.title}}</div>
              <!-- Type Radio -->
              <template v-if="q.type == 'radio'">
                <v-radio-group :rules="[]" v-model="form[q.id]">
                  <v-radio
                    v-for="o in q.options"
                    :key="'option-' + o.value"
                    :label="o.label"
                    :value="o.value"
                  ></v-radio>
                </v-radio-group>
              </template>
              <!-- Type Radio -->

              <!-- Type Select -->
              <template v-if="q.type == 'select'">
                <v-select
                  :rules="q.rules? q.rules.map(rule => rules[rule]): []"
                  v-model="form[q.id]"
                  :items="q.options"
                  :placeholder="q.placeholder"
                ></v-select>
              </template>
              <!-- Type Select -->

              <!-- Type Text -->
              <template v-if="q.type == 'text'">
                <v-text-field
                  :rules="q.rules? q.rules.map(rule => rules[rule]): [rules['max']]"
                  v-model="form[q.id]"
                  :placeholder="q.placeholder"
                ></v-text-field>
              </template>
              <!-- Type Text -->
            </div>

            <!-- Q 商品の満足度 -->
            <div class="survey-question">
              <div class="question">Q{{questions.length + 4}}. 商品の満足度を5段階でご評価ください</div>
              <v-select
                :rules="[rules.requiredSelect]"
                v-model="form.i7"
                :items="stars"
                placeholder="満足度を選択してください"
              ></v-select>
            </div>
            <!-- Q 商品の満足度 -->
          </v-form>
        </v-card-text>
      </v-card>
      <div class="text-center pa-4">
        <v-btn
          large
          depressed
          class="white--text"
          :style="`background-color:${brand.color.brand}`"
          @click="clickedNext"
        >
          <span class="title pl-4">次 へ</span>
          <v-icon class="pl-2">mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  directives: {
    mask
  },
  data: () => ({
    form: {
      i7: null
    },
    stars: [
      { text: "★★★★★ 満足", value: "5" },
      { text: "★★★★ やや満足", value: "4" },
      { text: "★★★ 可もなく不可もなく", value: "3" },
      { text: "★★ 不満足な点がやや多い", value: "2" },
      { text: "★ すべてが不満足", value: "1" }
    ],
    rules: {
      required: value => !!value || "入力してください",
      requiredSelect: value => !!value || "選択してください",
      max: v => !v || (v && v.length <= 255) || "255文字以内で入力してください"
    },
    valid: true
  }),
  methods: {
    clickedNext() {
      if (this.$refs.form.validate()) {
        this.goNext();
      }
    },
    goNext() {
      let form = {};
      [{ id: "i7" }, ...this.questions].forEach(q => {
        form[q.id] = this.form[q.id];
      });
      this.$store.commit("saveForm", form);
      this.$store.commit("saveStep", 4);
      this.$router.push({ name: "step4" });
    }
  },
  computed: {
    brand() {
      return this.$store.getters.brandInfo;
    },
    questions() {
      if (this.brand) {
        return this.brand.questions.map((q, idx) => {
          return Object.assign({ id: `q${idx + 1}` }, q);
        });
      }
      return [];
    }
  },
  mounted() {
    this.questions.forEach(q => {
      this.$set(this.form, q.id, null);
    });
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$store.state.step < 3) {
        vm.$router.push({ name: "home" });
      } else if (vm.$store.state.step > 3) {
        Object.keys(vm.$store.state.form).forEach(key => {
          vm.form[key] = vm.$store.state.form[key];
        });
      }
    });
  }
};
</script>