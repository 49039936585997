import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
import axios from 'axios'

Vue.use(Vuex);

const config = require("@/lib/config.yml");
const apiEndpoint = config.apiEndpoint;
const brands = config.brands.map(file => {
  return require(`@/lib/brands/${file}`);
});

export default new Vuex.Store({
  state: {
    allBrandNames: brands.map(item => item.name),
    form: {},
    step: 0,
    brand: '',
    apiEndpoint,
    products: null,
    pProducts: null,
    randNumber: 99
  },
  getters: {
    brandInfo: (state) => {
      const target = brands.find(brand => {
        return state.brand == brand.name;
      });
      if (target) {
        return target;
      }
      return null;
    },
    productInfo: (state) => {
      if (state.form.i3 && state.form.i3.length > 0 && state.products && state.products.length > 0) {
        const target = state.products.find(p => {
          return p.sku == state.form.i3;
        });
        if (target) {
          return target;
        }
      }
      return null;
    }
  },
  mutations: {
    initRandNumber(state) {
      // 0-99
      state.randNumber = Math.floor(Math.random() * 100);
    },
    saveForm(state, payload) {
      Object.keys(payload).forEach(key => {
        Vue.set(state.form, key, payload[key]);
      })
    },
    saveStep(state, step) {
      state.step = step;
    },
    setBrand(state, brand) {
      const isValid = brands.some(b => {
        return b.name == brand
      });
      if (isValid) {
        state.brand = brand;
      } else {
        state.brand = null;
      }
    },
    saveProducts(state, items) {
      state.products = items;
    },
    saveTargetProducts(state, items) {
      state.pProducts = items;
    }
  },
  actions: {
    getProducts({ state, commit }, brand) {
      axios(`${state.apiEndpoint}?type=products&brand=${brand}`).then(res => {
        if (res && res.data && res.data.success && res.data.data) {
          const data = res.data.data;
          const fields = data[0];
          let ret = [];
          data.forEach((vals, idx) => {
            if (idx > 0) {
              const obj = {};
              fields.forEach((field, cid) => {
                obj[field] = vals[cid];
              });
              ret.push(obj);
            }
          });
          commit('saveProducts', ret);
        }
      });
    },
    checkOrderNumber({ state, commit }, { orderNumber, callback, finalCallback }) {
      axios(`${state.apiEndpoint}?type=order&brand=${state.brand}&q=${orderNumber}`).then(res => {
        if (res && res.data && res.data.data) {
          const data = res.data.data;
          const fields = data[0];
          let ret = [];
          let targetIdx = null;
          data.forEach((vals, idx) => {
            if (idx == 0) {
              targetIdx = vals.indexOf('order-id');
            } else if (idx > 0 && targetIdx != null && targetIdx >= 0) {
              if (vals[targetIdx] == orderNumber) {
                const obj = {};
                fields.forEach((field, cid) => {
                  obj[field] = vals[cid];
                });
                ret.push(obj);
              }
            }
          });
          if (ret && ret.length > 0) {
            const purchased = moment(ret[0]["purchase-date"]);
            const today = moment();
            const days = today.diff(purchased, "days");
            console.log(days);
            console.log(today);
            console.log(purchased);
            if (days >= 2) {
              commit("saveTargetProducts", ret);
              callback();
              return;
            }
          }
        }
        alert(
          "本キャンペーンは受け取るには、購入日から7日以上経過している必要があります。"
        );
      }).catch(e => {
        alert(
          "エラーが発生したため送信できませんでした。しばらく待ってから再度送信してください。"
        );
      }).then(() => {
        finalCallback();
      });
    },
    sendForm({ state, getters }, { callback, errorCallback }) {
      const params = {
        satisfaction: (state.form.i7 ? state.form.i7 : ''),
        'review-check': state.form.i9 ? "checked" : "無",
        'review-content': state.form.i8 ? state.form.i8 : "無",
        email: state.form.i10,
        channel: state.form.i1,
        'gift-type': state.form.gift,
        'order-id': state.form.i2,
        sku: state.form.i3,
        date: moment().format("YYYY-MM-DD HH:mm:ss")
      };
      getters.brandInfo.questions.forEach((q, idx) => {
        params[`q${idx + 1}-question`] = q.title;
        params[`q${idx + 1}-answer`] = state.form[`q${idx + 1}`] ? state.form[`q${idx + 1}`] : '無';
      });
      axios({
        method: 'post',
        url: `${state.apiEndpoint}?brand=${state.brand}`,
        data: params,
        headers: {
          'Content-Type': 'text/plain;charset=utf-8'
        }
      }).then(res => {
        if (res && res.data && res.data.success && res.data.success == true) {
          callback();
        } else {
          errorCallback();
        }
      }).catch(() => {
        errorCallback();
      })
    }
  }
})
