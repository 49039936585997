<template>
  <div>
    <v-container class="text-center mt-4 pt-3">
      <v-icon color="success lighten-1" size="100">mdi-check-circle-outline</v-icon>
      <div class="headline pt-4">お申し込みを受け付けました</div>
    </v-container>
    <v-container>
      <div class="mx-auto pt-4" style="max-width:720px;">
        <v-divider></v-divider>
        <div class="survey-question">
          <div class="title pt-3">
            <v-icon class="pr-1 pb-1">mdi-menu-right</v-icon>LINEをお持ちのお客様
          </div>
          <div class="pl-3">
            注文番号の再確認のため、友だち追加からLINE@を登録して頂き、
            LINE@宛に注文番号を再度お送りください。
            注文番号が確認でき次第、Eメールにて{{giftCard}}を進呈いたします。
          </div>
          <div class="text-center py-4">
            <a :href="brand.lineAddFriendUrl" target="_blank">
              <img
                style="width: auto;"
                height="36"
                border="0"
                alt="友だち追加"
                src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
              />
            </a>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="survey-question">
          <div class="title pt-3">
            <v-icon class="pr-1 pb-1">mdi-menu-right</v-icon>LINEをお持ちでないお客様
          </div>
          <div class="pl-3">
            大変お手数をおかけ致しますが、
            下記アドレス宛に注文番号をお送り下さいませ。
            注文が確認でき次第、Eメールにて{{giftCard}}を進呈いたします。
          </div>
          <div class="text-center py-4">
            <v-icon class="grey--text pr-1 pb-1">mdi-email-outline</v-icon>
            <a :href="`mailto:${brand.emailAddress}`">
              <span class="title">{{brand.emailAddress}}</span>
            </a>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="text-center py-4 my-4">この度は貴重なご意見を頂きましてありがとうございました！</div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  computed: {
    brand() {
      return this.$store.getters.brandInfo;
    },
    giftCard() {
      if (this.brand) {
        const type = this.$store.state.form.gift;
        if (type == "amazon_giftcard") {
          return "Amazonギフト券";
        } else if (type == "starbucks_giftcard") {
          return "スターバックスギフトカード";
        } else if (type == "quocard") {
          return "クオカード";
        }
      }
      return "ギフトカード";
    }
  }
};
</script>
