<template>
  <v-container>
    <div class="mx-auto pt-4 my-survey-wrapper">
      <v-card flat>
        <v-card-text class="pa-0">
          <v-form ref="form">
            <!-- Q3 -->
            <div class="survey-question">
              <div class="question">Q3. レビューする商品を選択してください</div>
              <div class="mx-auto pt-4" style="max-width:600px;">
                <div v-for="(item, idx) in products" :key="`item-${idx}`">
                  <product-card
                    :item="item"
                    :active="form.i3 == item.sku"
                    @click-product="form.i3 = item.sku"
                  ></product-card>
                </div>
              </div>
            </div>
            <!-- Q3 -->
          </v-form>
        </v-card-text>
      </v-card>
      <div class="text-center pa-4">
        <v-btn
          large
          depressed
          class="white--text"
          :style="`background-color:${brand.color.brand}`"
          @click="clickedNext"
          :disabled="form.i3 == null"
        >
          <span class="title pl-4">
            <template v-if="form.i3 == null">商品を選択してください</template>
            <template v-else>次 へ</template>
          </span>
          <v-icon class="pl-2">mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import ProductCard from "@/components/ProductCard.vue";

export default {
  components: {
    ProductCard
  },
  data: () => ({
    form: {
      i3: null
    },
    loading: false
  }),
  methods: {
    clickedNext() {
      const i3 = this.form.i3;
      this.$store.commit("saveForm", { i3 });
      this.$store.commit("saveStep", 3);
      this.$router.push({ name: "step3" });
    }
  },
  computed: {
    brand() {
      return this.$store.getters.brandInfo;
    },
    allProducts() {
      return this.$store.state.products;
    },

    pProducts() {
      return this.$store.state.pProducts;
    },
    products() {
      if (this.allProducts && this.pProducts) {
        return this.pProducts.map(item => {
          return Object.assign(
            item,
            this.allProducts.find(pr => {
              return item.sku == pr.sku;
            })
          );
        });
      }
      return [];
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$store.state.step < 2) {
        vm.$router.push({ name: "home" });
      } else if (vm.$store.state.step > 2) {
        vm.form.i3 = vm.$store.state.form.i3;
      }
    });
  }
};
</script>