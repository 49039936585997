<template>
  <v-container fluid pa-0 pb-4>
    <v-stepper
      dark
      :value="page"
      class="elevation-0 my-stepper"
      :style="`border-radius:0; background:${brand.color.headerBackground} !important;`"
    >
      <v-stepper-header>
        <template v-for="s in steps">
          <v-stepper-step
            :complete="s.step < page"
            :key="'step-' + s.step"
            :step="s.step"
          >{{s.title}}</v-stepper-step>
          <v-divider :key="'step-d-' + s.step" v-if="s.step < steps.length"></v-divider>
        </template>
      </v-stepper-header>
    </v-stepper>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    steps: [
      {
        step: 1,
        title: "注文内容の確認"
      },
      {
        step: 2,
        title: "レビュー商品の確認"
      },
      {
        step: 3,
        title: "アンケートの回答"
      },
      {
        step: 4,
        title: "レビュー投稿"
      }
    ]
  }),
  props: ["page"],
  computed: {
    brand() {
      return this.$store.getters.brandInfo;
    }
  }
};
</script>

